/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.    
    
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		$(document).ready(function(){
		
		       
			//SVG .png replacement
			svgeezy.init(false, 'png');
	            
	        //****** Lightbox ********//
	        $('a[data-rel^=popup]').lightcase({
			    iframe: {
			        allowfullscreen: 1
			    }
			});
	        $('a[data-rel^=inline-popup]').lightcase({
		         type: 'inline'
	        });
	        
	        
			//footer promo slider init
			$('.footer-promo').slick({
				  dots: false,
				  autoplay: true,
				  autoplaySpeed: 5000,
				  arrows: false,
				  infinite: true,
				  slidesToScroll: 1,
				  slidesToShow: 1,
				  fade: true,
				  adaptiveHeight: true
			});
			
			
			/* CREATIVE VIDEO SOLUTIONS */
			$('.creative-video-solutions .item').matchHeight();
			
			/* TECHNOLOGY PARTNERS */
			if($('#technology-partners-tabs').length){
			  	$( "#technology-partners-tabs" ).tabs();
			  	
			  	/*
				$("#technology-partners-tabs .item .button").click(function(){
				  	var button_copy = $(this).find('span').html();
					
					$('#' + $(this).data('trigger')).slideToggle();
					
					if($(this).hasClass('opened')){
						$(this).html('More about '+button_copy+' integration');
						$(this).removeClass('opened');
					}
					else{
						$(this).html('Hide '+button_copy+' integration details');
						$(this).addClass('opened');
					}
					
					
					return false;
			  	});
			  	*/
			}
			
			
			/* NEWS */
			$('.news-list .item').matchHeight();
			
			/* EVENTS */
			$('.events-list .item').matchHeight();
			
			/* PRODUCTS */
			if($('.products-list').length){
				$('.products-list .item').matchHeight();
				$('.products-list .item .img-container').matchHeight();
			}
			
			/* PRODUCTS SINGLE */
			if($('.product-single').length){
				$('.product-single .wpb_column').matchHeight();
			}
			
			
			/* CHANGE TABS ON WINDOW HASH CHANGE */
			$(window).on('hashchange', function () {
				if($('.ui-tabs').length){
					  if (!location.hash) {
					    $('.ui-tabs').tabs('option', 'active', 0); // activate first tab by default
					    return;
					  }
					  $('.ui-tabs > ul > li > a').each(function (index, a) {
					    if ($(a).attr('href') == location.hash) {
					      $('.ui-tabs').tabs('option', 'active', index);
					    }
					  });
			   }
			});
	        		
		});
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    
    // Home page
    'home': {
      init: function() {

		  
			//set server image positions
			position_hp_slide_servers();
			
			//homepage slider init
			$('.homepage-slider').slick({
				  dots: true,
				  arrows: false,
				  infinite: true,
				  slidesToScroll: 1,
				  slidesToShow: 1,
				  fade: true,
				  adaptiveHeight: true
			});
			
			//window resize
			$(window).on('resize', function(){
				//set server image positions
				position_hp_slide_servers();
			});

        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

	// Contact page
    'contact': {
      init: function() {

		
      }
    },
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
	
	// Grayscale w canvas method
	function position_hp_slide_servers(){
		$('.homepage-slider .server-image').each(function(){
			$(this).css('margin-top',-$(this).height()/2);
		});
    }
	    

})(jQuery); // Fully reference jQuery after this point.
